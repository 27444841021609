.range {
  --active-color: white;
  --inactive-color: rgba(255, 255, 255, 0.2);
  --progress: 50%;
  -webkit-appearance: none;
  background: linear-gradient(
    to right,
    var(--active-color) 0%,
    var(--active-color) var(--progress),
    var(--inactive-color) var(--progress),
    var(--inactive-color) 100%
  );
  height: 2px;
  border-radius: 2px;
}

/* Pragmatic approach */
[dir='rtl'] .range {
  background: linear-gradient(
    to left,
    var(--active-color) 0%,
    var(--active-color) var(--progress),
    var(--inactive-color) var(--progress),
    var(--inactive-color) 100%
  ) !important;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  border-radius: 100%;
  background: var(--active-color);
  box-shadow: 10 10 black;
}
