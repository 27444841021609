@layer base {
  @font-face {
    font-family: 'Euclid Circular B';
    src: url('../public/fonts/EuclidCircularB/EuclidCircularB-Bold.otf')
      format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Euclid Circular B';
    src: url('../public/fonts/EuclidCircularB/EuclidCircularB-BoldItalic.otf')
      format('opentype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Euclid Circular B';
    src: url('../public/fonts/EuclidCircularB/EuclidCircularB-Semibold.otf')
      format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Euclid Circular B';
    src: url('../public/fonts/EuclidCircularB/EuclidCircularB-SemiboldItalic.otf')
      format('opentype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Euclid Circular B';
    src: url('../public/fonts/EuclidCircularB/EuclidCircularB-Medium.otf')
      format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Euclid Circular B';
    src: url('../public/fonts/EuclidCircularB/EuclidCircularB-MediumItalic.otf')
      format('opentype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Euclid Circular B';
    src: url('../public/fonts/EuclidCircularB/EuclidCircularB-Regular.otf')
      format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Euclid Circular B';
    src: url('../public/fonts/EuclidCircularB/EuclidCircularB-RegularItalic.otf')
      format('opentype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
}
