@layer components {
  .tabs-container {
    display: flex;
    width: fit-content;
    border-radius: 15px;
    @apply border-2 border-gray-400;
    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 56px;
      height: 40px;
      border-radius: 13px;
      position: relative;
      font-size: 14px;
      font-weight: 600;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
    .tab--active {
      @apply bg-gray-400;
    }
    .tab--disabled {
      @apply text-gray-400;
      pointer-events: none;
    }
  }
}
