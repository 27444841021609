@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
}

.slide-in-out {
  animation: slide-in 0.3s ease-in forwards, slide-out 0.3s ease-out forwards 5s;
}
