@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './buttons.css';
@import './fonts.css';
@import './onetrust.css';
@import './range.css';
@import './animations.css';

@import './../ui/tabs/tabs.css';
@import './../ui/editorButton/editorButton.css';
@import './../ui/badge/badge.css';
@import '../features/imageEditor/aiToolbar/editorPreviews/editorPreviews.css';

@layer base {
  html,
  body,
  #__next,
  #__next > div {
    @apply h-full;
  }
  body {
    @apply section-background !text-gray-200 !bg-black-background;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply contrast-high;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* scrollbar custom style */
  * {
    /* setting variables here make all items to get scrollbar color gray unless explicit item redeclare
    ** parent override will not effect childrens */
    --scrollbar-track-color: theme('colors.gray.500');
    --scrollbar-thumb-color: rgba(255, 255, 255, 0.15);
    --scrollbar-size: 6px;

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: var(--scrollbar-size); /* vertical scrollbar */
      height: var(--scrollbar-size); /* horizontal scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbar-track-color);
      @apply rounded-full;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar-thumb-color);
      @apply rounded-full;
      &:hover {
        background-color: var(--scrollbar-thumb-color);
      }
    }

    &.scrollbar-light {
      --scrollbar-track-color: theme('colors.gray.100');
      --scrollbar-thumb-color: rgba(0, 0, 0, 0.15);
    }

    &.scrollbar-hide {
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        /* Chrome, Edge, and Safari */
        display: none;
      }
    }

    /*
      Mimic default behavior.
      hide scrollbar only if element not hovered
    */
    &.scrollbar-overlay:not(:hover) {
      /* hide setting scroll items to transparent instead of delete with
         to avoid flickering on toggle / layout changes */
      --scrollbar-thumb-color: transparent;
      --scrollbar-track-color: transparent;
    }
  }
}

@layer components {
  .container-full {
    @apply container max-w-none;
  }
  .prose thead {
    color: inherit;
  }
  .prose a.btn {
    text-decoration: none;
  }
}

@layer utilities {
  .center-y {
    @apply transform-gpu -translate-y-1/2 top-1/2;
  }
  .center-x {
    @apply transform-gpu -translate-x-1/2 left-1/2;
  }
  .center {
    @apply transform-gpu -translate-y-1/2 top-1/2 -translate-x-1/2 left-1/2;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .img-gradient-overlay {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0)
      );
      opacity: 0.75;
    }
  }

  @supports (-webkit-touch-callout: none) {
    .h-screen {
      max-height: -webkit-fill-available;
    }
    .min-h-screen {
      min-height: -webkit-fill-available;
    }
  }

  @responsive {
    .aspect-h {
      width: 0;
      padding-bottom: 0;
      padding-right: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
    }
  }
}

/* Global CSS (for things like reCAPTCHA iframe) */
.grecaptcha-badge {
  visibility: hidden;
}

/* Global CSS to avoid OneTrusts override */
.ot-floating-button {
  display: none !important;
}

.frosted {
  -webkit-backdrop-filter: blur(15px); /* Safari 9+ */
  backdrop-filter: blur(15px); /* Chrome and Opera */
  background-color: rgb(0 0 0 / 25%);
}
