@layer components {
  .btn {
    @apply M18 rounded-3xl border-2 flex items-center justify-center transition-colors;
  }
  @responsive {
    .btn--xl {
      @apply px-6 py-4.25 space-s-3 rounded-full;
    }
    .btn--large {
      @apply px-6 py-3 space-s-3 rounded-full;
    }
    .btn--medium {
      @apply px-4 py-3 space-s-2;
    }
  }
  .btn--primary {
    @apply text-white bg-primary-accent border-primary-accent hover:opacity-90;
  }
  .btn--black {
    @apply text-white bg-gray-500 border-gray-500 hover:opacity-90;
  }
  .btn--white {
    @apply text-black bg-white border-white hover:opacity-90;
    font-weight: 600;
  }
  /* Doesn't work on light backgrounds */
  .btn--secondary {
    @apply border-1.5 border-overlay-white-15 hover:bg-overlay-black-20;
  }
}

button:disabled {
  @apply !opacity-50 cursor-not-allowed;
}

/*
#enable-free-trial:focus + label > div[role='presentation'] {
  @apply ring;
}
*/
