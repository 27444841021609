@layer components {
  .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 1px 8px;
    border-radius: 40px;
    font-size: 9px;
    font-weight: 700;
    text-transform: uppercase;
    width: fit-content;
    height: 20px;
  }
  .badge--absolute {
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translate(-50%, 50%);
  }
  .pro {
    @apply bg-primary-accent;
  }
  .soon {
    @apply text-black bg-secondary-yellow;
  }
  .new {
    @apply text-primary-accent bg-primary-accent-150;
  }
  .large {
    @apply text-secondary-purple bg-secondary-purple-20 rounded-lg tracking-wider;
    padding: 14px 16px;

    font-size: 10px;
    font-weight: 600;
  }
  .new-animated {
    @apply text-primary-accent flex items-center me-2;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    padding: 0;
  }
  .new-animated:before {
    @apply bg-primary-accent rounded-full;
    content: '';
    display: inline-block;
    margin: 4px;
    height: 8px;
    width: 8px;
    border: 2px solid #661726;
    animation: bullet-animation 0.5s ease-in forwards 2s;
  }
  .new-animated p {
    opacity: 1;
    overflow: hidden;
    animation: hide-label 0.5s ease-in forwards 2s;
  }

  @keyframes hide-label {
    0% {
      opacity: 1;
      width: 20px;
    }
    100% {
      opacity: 0;
      width: 0;
    }
  }

  @keyframes bullet-animation {
    0% {
      margin: 4px;
      border: 2px solid #661726;
      background-color: rgba(255, 58, 92, 0.5);
    }
    100% {
      margin: 0;
      border: 2px solid rgba(255, 58, 92, 1);
      background-color: rgba(255, 58, 92, 1);
    }
  }
}
